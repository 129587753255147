exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-projetos-index-jsx": () => import("./../../../src/pages/projetos/index.jsx" /* webpackChunkName: "component---src-pages-projetos-index-jsx" */),
  "component---src-pages-projetos-wp-meuprojetov-2-slug-jsx": () => import("./../../../src/pages/projetos/{wpMeuprojetov2.slug}.jsx" /* webpackChunkName: "component---src-pages-projetos-wp-meuprojetov-2-slug-jsx" */),
  "component---src-pages-sobre-index-jsx": () => import("./../../../src/pages/sobre/index.jsx" /* webpackChunkName: "component---src-pages-sobre-index-jsx" */)
}

